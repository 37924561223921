/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <Column className="mt--40 pb--80 pt--0" name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--0" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"Fotogalerie"}>
              </Title>

              <Image className="--left pb--02 pl--02 pr--02 pt--02" alt={""} src={"https://cdn.swbpg.com/o/11591/f54a559b8f5e4d9086b55732bbd82975.svg"} svg={false} href={"/"} sizes={"100vw"} style={{"maxWidth":36}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" anim={null} name={"mcz8zy8gkf"} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pl--0 pr--0" anim={null} columns={"3"}>
            
            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s4 pb--80 pt--80" anim={"2"} name={"xi6rhmztau"} animS={"4"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--0" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":""}} content={"Portréty"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":573}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"1zv03r3aiu9i"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
        </Column>


        <Column className="pb--80 pt--20" anim={null} name={"mcz8zy8gkf"} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pl--0 pr--0" anim={null} columns={"3"}>
            
            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/103/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/103/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/103/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/103/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/103/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" anim={null} name={"kontakt"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--2 pb--30 pt--25 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Ozvěte se mi ...</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'>Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Tvorba web stránek</a></span>"}>
              </Text>

              <Subtitle className="subtitle-box lh--16 mb--40" content={"<span style='color: var(--color-dominant)'>Petr Domanický<br>+420 797 811 2X0<br>info@vase-stranky.com</span>"}>
              </Subtitle>

              <Image className="--left pb--06 pl--06 pr--06 pt--06" alt={""} src={"https://cdn.swbpg.com/o/11591/cac5c902f16a47b7b7aea31fabd7fc79.svg"} svg={false} href={"mailto:[[UNIemail]]"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}